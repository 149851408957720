<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "trainer-presentation-assessment-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>

    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        label="name"
                        :disabled="
                          currentRouteName ==
                            'trainer-presentation-assessment-update'
                        "
                        :options="programs"
                        :selectable="(options) => isSelectableProgram(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-presentation-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedProgram"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :disabled="
                          currentRouteName ==
                            'trainer-presentation-assessment-update'
                        "
                        :options="batch"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                        ])
                      "
                    >
                      <v-select
                        v-model="selectedBatch"
                        label="batch_no"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Type
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="
                          selectedLevel = null;
                          selectedEmployee = null;
                          presentation.employee_id = '';
                          presentation.employee_name = '';
                          presentation.join_date = '';
                          weights = [];
                          selectedWeight = '';
                          criterias = [];
                          totalScore = 0;
                        "
                        v-model="presentation.type"
                        :disabled="
                          currentRouteName ==
                            'trainer-presentation-assessment-update'
                        "
                        :options="['In-Class', 'OJT']"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('type')"
                        :vuelidateErrors="{
                          errors: v$.presentation.type.$errors,
                          value: 'Type',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                        ])
                      "
                    >
                      <v-select
                        v-model="presentation.type"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedLevel"
                        v-model="selectedLevel"
                        label="name"
                        :disabled="
                          currentRouteName ==
                            'trainer-presentation-assessment-update'
                        "
                        :options="levels"
                        :selectable="(options) => isSelectableLevel(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.presentation.level.$error || errorFor('level'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('level')"
                        :vuelidateErrors="{
                          errors: v$.presentation.level.$errors,
                          value: 'Level',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-presentation-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedLevel"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee name
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedEmployee"
                        v-model="selectedEmployee"
                        label="employee_name"
                        :disabled="
                          currentRouteName ==
                            'trainer-presentation-assessment-update'
                        "
                        :options="employeeLists"
                        :selectable="(option) => isSelectable(option)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedEmployee.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedEmployee.$errors,
                          value: 'Employee Name',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-presentation-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedEmployee"
                        label="employee_name"
                        :clearable="false"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="presentation.employee_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-4 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="presentation.join_date"
                        disabled
                      />
                    </div>
                  </div>

                  <!-- <div class="row mb-3">
                    <label
                      for="evaluating_criteria"
                      class="col-md-4 form-label"
                    >
                      Choose Weight
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-presentation-assessment-create',
                          'trainer-presentation-assessment-update',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedCriteria($event)"
                        label="name"
                        :options="weights"
                        :reduce="(weight) => weight.id"
                        :clearable="false"
                        v-model="selectedWeight"
                        :selectable="(options) => isSelectableCriteria(options)"
                        :class="{
                          'p-invalid':
                            v$.selectedWeight.$error ||
                            errorFor('Criteria Weight'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('Criteria Weight')"
                        :vuelidateErrors="{
                          errors: v$.selectedWeight.$errors,
                          value: 'Criteria Weight',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-presentation-assessment-list'])
                      "
                    >
                      <v-select
                        label="evaluating_criteria_name"
                        :clearable="false"
                        v-model="selectedWeight"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="mt-3 row g-3">
                <h5 class="col-5">Weight</h5>
                <h5 class="col-3">Total Score</h5>
                <h5 class="col-3">Remark</h5>
                <h5 class="col-1">Action</h5>
              </div>
              <div
                class="progress mb-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <template
                v-if="selectedBatch && selectedProgram && selectedEmployee"
              >
                <div class="row g-3" v-for="(item, index) in weights">
                  <div class="col-5">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <h5>{{ item.name }}</h5>

                      <div
                        class="me-5"
                        style="cursor: pointer;"
                        @click="item.isOpen = !item.isOpen"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="ms-3 mt-1" v-if="item.isOpen">
                      <p
                        v-for="(cri, subIndex) in item.trainer_criterias"
                        class="mb-3"
                      >
                        {{ cri.name }}
                      </p>
                    </div>
                  </div>
                  <div class="col-3">
                    <h5>{{ item.totalScore ?? 0 }}</h5>

                    <!-- v-model="criteria.selectedScore"
                        :options="scoreOptions(criteria)" -->
                    <!-- @option:selected="updateTotalScore" -->
                    <template
                      v-for="(cri, subIndex) in item.trainer_criterias"
                      v-if="item.isOpen"
                    >
                      <v-select
                        v-model="cri.selectedScore"
                        @option:selected="updateTotalScore(item)"
                        :clearable="false"
                        :disabled="
                          !hasPermissions([
                            'trainer-presentation-assessment-create',
                            'trainer-presentation-assessment-edit',
                          ])
                        "
                        :options="scoreOptions(cri)"
                        class="w-75 mb-3"
                      ></v-select>
                    </template>
                  </div>
                  <div class="col-3">
                    <h5 class="opacity-0">Remark</h5>

                    <!-- v-model="criteria.selectedScore"
                        :options="scoreOptions(criteria)" -->
                    <!-- @option:selected="updateTotalScore" -->
                    <template
                      v-for="(cri, subIndex) in item.trainer_criterias"
                      v-if="item.isOpen"
                    >
                      <input
                        type="text"
                        class="form-control w-75 mb-3"
                        placeholder="Remark"
                        aria-label="Remark"
                        v-model="cri.remark"
                        :disabled="
                          !hasPermissions([
                            'trainer-presentation-assessment-create',
                            'trainer-presentation-assessment-edit',
                          ])
                        "
                      />
                    </template>
                  </div>
                  <div
                    class="col-1"
                    v-if="
                      hasPermissions([
                        'trainer-presentation-assessment-create',
                        'trainer-presentation-assessment-edit',
                      ])
                    "
                  >
                    <svg
                      data-bs-toggle="modal"
                      :data-bs-target="'#' + modalId"
                      @click="selectedId = item.id"
                      style="cursor: pointer;"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="m9.4 16.5l2.6-2.6l2.6 2.6l1.4-1.4l-2.6-2.6L16 9.9l-1.4-1.4l-2.6 2.6l-2.6-2.6L8 9.9l2.6 2.6L8 15.1zM7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21z"
                      />
                    </svg>
                  </div>
                </div>
              </template>

              <!-- <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Remark:</h4>
                </div>
              </div>

              <template
                v-if="
                  hasPermissions([
                    'trainer-presentation-assessment-create',
                    'trainer-presentation-assessment-update',
                  ])
                "
                >
                <div
                  class="row g-3"
                  v-for="(criteria, index) in criterias"
                  :key="index"
                >
                  <div class="col-sm-4">
                    <p class="mb-3 pt-2 pl-2">{{ criteria.name }}</p>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <v-select
                        v-model="criteria.selectedScore"
                        :options="scoreOptions(criteria)"
                        :clearable="false"
                        @option:selected="updateTotalScore"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <input
                        type="text"
                        class="form-control mt-2"
                        placeholder="Remark"
                        aria-label="Remark"
                        v-model="criteria.remark"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <template
                v-else-if="hasPermissions(['trainer-presentation-assessment-update'])"
              >
                <div
                  class="row g-3"
                  v-for="(criteria, index) in criterias"
                  :key="index"
                >
                  <div class="col-sm-4">
                    <p class="mb-3 pt-2 pl-2">{{ criteria.name }}</p>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <v-select
                        v-model="criteria.selectedScore"
                        :clearable="false"
                        disabled
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <input
                        type="text"
                        class="form-control mt-2"
                        placeholder="Remark"
                        aria-label="Remark"
                        v-model="criteria.remark"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </template>

              <div class="row g-3 pt-2">
                <div class="col-sm-4">Total Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="totalScore"
                    disabled
                  />
                </div>
              </div> -->

              <div
                class="row"
                v-if="
                  hasPermissions([
                    'trainer-presentation-assessment-create',
                    'trainer-presentation-assessment-update',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link
                      :to="{ name: 'trainer-presentation-assessment' }"
                    >
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      v-if="weights?.length == 0"
                      style="cursor: not-allowed"
                      data-bs-toggle="tooltip"
                      title="Your total score is still 0."
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                    >
                      <span>Save</span>
                    </button>
                    <button
                      v-else
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName ===
                        'trainer-presentation-assessment-update'
                          ? updateTrainerPresentationAssessment()
                          : createTrainerPresentationAssessment()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ReusableModal :modalId="modalId">
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
        Close
      </button>
      <button type="button" class="btn btn-danger" @click="filterWeight">
        Delete
      </button>
    </template>
  </ReusableModal>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";
import { levels } from "../../../const";
import ReusableModal from "../../../components/shared/SampleModal.vue";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    ReusableModal,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      modalId: "exampleModal",
      selectedId: "",
      programs: this.$store.getters["odoo/getAllPrograms"],
      batch: [],
      levels: levels,
      employeeLists: [],
      criterias: [],
      totalScore: 0,
      existedEmployees: [],
      presentation: {
        type: "",
        program_id: "",
        program_name: "",
        program_short_name: "",
        program_batch_id: "",
        program_batch_no: "",
        join_date: "",
        available: "",
        level: "",
      },
      weights: [],
      selectedProgram: null,
      selectedBatch: null,
      selectedEmployee: null,
      selectedWeight: null,
      selectedLevel: null,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedEmployee: { required },
      selectedLevel: { required },
      // selectedWeight: { required },
      presentation: {
        type: { required },
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        program_batch_id: { required },
        program_batch_no: { required },
        join_date: { required },
        available: { required },
        level: {
          required: requiredIf(() => {
            return this.presentation.type != "Orientation";
          }),
        },
      },
    };
  },
  methods: {
    filterWeight() {
      this.weights = this.weights?.filter((w) => w.id != this.selectedId);
      this.selectedId = "";
      const modal = document.getElementById(this.modalId);
      if (modal) {
        const bsModal = bootstrap.Modal.getInstance(modal);
        if (bsModal) {
          bsModal.hide();
        }
      }
    },
    isSelectableCriteria(options) {
      if (!this.selectedWeight || !options) return true;
      if (Array.isArray(this.selectedWeight)) {
        return this.selectedWeight[0].id != options.id;
      } else {
        return this.selectedWeight.id != options.id;
      }
    },
    isSelectableProgram(options) {
      if (!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id;
    },
    isSelectableBatch(options) {
      if (!this.selectedBatch || !options) return true;
      return this.selectedBatch.id != options.id;
    },
    isSelectableLevel(options) {
      if (!this.selectedLevel || !options) return true;
      return this.selectedLevel.id != options.id;
    },
    isSelectable(options) {
      if (this.selectedEmployee) {
        const empExist = this.existedEmployees.some(
          (item) =>
            item.employee_id == this.selectedEmployee.employee_id &&
            item.level == this.selectedLevel.name &&
            item.type == this.presentation.type
          // && item.assessment_criteria_id == this.selectedWeight.id
        );
        // If the batch doesn't exist, add it to existedEmployees
        if (!empExist) {
          this.existedEmployees = this.existedEmployees.filter(
            (item) => !item.is_selected
          );
          this.existedEmployees.push({
            employee_id: this.selectedEmployee.employee_id,
            level: this.selectedLevel.name,
            type: this.presentation.type,
            // assessment_criteria_id: this.selectedWeight.id,
            is_selected: true,
          });
        }
      }
      // Now, check if the options.id exists in existedEmployees
      return !this.existedEmployees.find(
        (item) =>
          item.employee_id == options.employee_id &&
          item.level == this.selectedLevel.name &&
          item.type == this.presentation.type
        // && item.assessment_criteria_id == this.selectedWeight.id
      );
    },
    // async getAllLevels() {
    //   await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
    //     this.levels = response.data.data;
    //   });
    // },
    async getAllCriterias() {
      let program_id = this.presentation.program_id;
      let level = this.presentation.level;
      let type = this.presentation.type;

      if (!program_id || !level || !type || !this.selectedBatch) return;

      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/trainer-assessment-getCriteria?program_id=${program_id}&type=${type}&level=${level}&is_presentation=1`
        )
        .then((response) => {
          // this.weights = [];
          // let res = response.data.data;
          // for(let val of res){
          //   if(val.trainer_criterias.length) {
          //     this.weights.push(val);
          //   }
          // }
          // console.log('mmt', res)
          //   if(res.trainer_criterias) {
          //       this.weights = res;
          //       console.log('You true')
          //   }else{
          //     console.log(res.trainer_criterias)
          //   }

          this.weights = [];
          let res = response.data.data;
          for (let val of res) {
            if (val.trainer_criterias.length) {
              this.updateTotalScore(val);
              if (val?.trainer_criterias?.length > 0) {
                val.trainer_criterias.forEach((item) => {
                  item.selectedScore = item.lowest_score;
                });
              }
              this.weights.push(val);
            }
          }

          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong,");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    getSelectedCriteria(val) {
      this.criterias = [];
      this.totalScore = 0;

      this.criterias = val.trainer_criterias;
      this.selectedWeight = val;
      this.totalScore = val.trainer_criterias.reduce(
        (total, value) => total + value.lowest_score,
        0
      );
      this.criterias.forEach((criteria) => {
        criteria.selectedScore = criteria.lowest_score;
        criteria.remark = null;
      });
    },

    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedBatch = null;
        this.criterias = [];
        this.totalScore = 0;
        this.clearData();
        this.presentation.program_id = this.selectedProgram.id;
        this.presentation.program_name = this.selectedProgram.name;
        this.presentation.program_short_name = this.selectedProgram.short_name;
        this.getBatchByProgram(this.presentation.program_id);
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.clearData();
        this.presentation.program_batch_id = this.selectedBatch.id;
        this.presentation.program_batch_no = this.selectedBatch.batch_no;
        this.presentation.available = this.selectedBatch.available;
      }
    },
    getSelectedEmployee() {
      if (this.selectedEmployee) {
        this.presentation.employee_id = this.selectedEmployee.employee_id;
        this.presentation.employee_name = this.selectedEmployee.employee_name;
        this.presentation.join_date = this.selectedBatch.join_date;
      }
    },
    getSelectedLevel() {
      if (!this.selectedBatch) return;
      if (this.selectedLevel) {
        this.selectedWeight = null;
        this.criterias = [];
        this.totalScore = 0;
        this.selectedEmployee = null;
        this.presentation.level = this.selectedLevel.name;
        this.getEmployeeByBatch(this.presentation.program_batch_id);
        this.getAllCriterias();
      }
    },
    clearData() {
      this.selectedEmployee = null;
      this.selectedWeight = null;
      this.selectedLevel = null;
      this.employeeLists = [];
      this.presentation.employee_id = "";
      this.presentation.employee_name = "";
      this.presentation.type = "";
      this.presentation.join_date = "";
      this.criterias = [];
      this.totalScore = 0;
    },

    updateTotalScore(item) {
      // let totalScore = 0;
      // for (let i = 0; i < this.criterias.length; i++) {
      //   totalScore += this.criterias[i].selectedScore;
      // }
      // this.totalScore = totalScore;

      let totalScore = 0;

      for (let i = 0; i < item?.trainer_criterias?.length; i++) {
        if (!item?.trainer_criterias[i].selectedScore) {
          item.trainer_criterias[i].selectedScore =
            item.trainer_criterias[i].lowest_score;
        }
        totalScore += Number(item?.trainer_criterias[i].selectedScore);
      }
      item.totalScore = totalScore;
    },

    scoreOptions(criteria) {
      const options = [];
      for (let i = criteria.lowest_score; i <= criteria.highest_score; i++) {
        options.push(i);
      }
      return options;
    },

    async getBatchByProgram(program_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-batches/list?program_id=${program_id}`
        )
        .then((response) => {
          this.batch = response.data.data;

          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeByBatch(batch_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/program-batch/employees/list/${batch_id}`)
        .then((response) => {
          const isCreateRoute =
            this.currentRouteName === "trainer-presentation-assessment-create";

          this.employeeLists = response.data.data.employee_ids
            .map((item) => {
              if (isCreateRoute && item.employee.active) {
                return {
                  employee_name: item.employee.name,
                  employee_id: item.employee.emp_id,
                };
              }
              return null;
            })
            ?.filter((item) => item !== null);

          console.log(this.employeeLists, "emp lists");
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },

    async createTrainerPresentationAssessment() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.isLoading = true;

      // let criteria = this.criterias.map((criteria) => {
      //   return {
      //     trainer_criteria_id: criteria.id,
      //     score: criteria.selectedScore,
      //     remark: criteria.remark,
      //   };
      // });

      // let toSendData = {
      //       program_id: this.presentation.program_id,
      //       batch_id: this.presentation.program_batch_id,
      //       employee_id: this.presentation.employee_id,
      //       assessor_employee_id: this.$store.state.auth.user.employee_id,
      //       type: this.presentation.type,
      //       level : this.presentation.level,
      //       assessment_point : this.selectedWeight.point,
      //       assessment_criteria_id: this.selectedWeight.id,
      //       trainer_criterias : criteria,
      //       is_presentation : 1
      // };

      let criterias = [];
      let pointsCriIds = [];

      this.weights.forEach((weight) => {
        pointsCriIds.push({
          point: weight.point,
          id: weight.id,
        });

        weight.trainer_criterias.forEach((cri) => {
          const data = {
            trainer_criteria_id: cri.id,
            score: cri.selectedScore,
            remark: cri.remark,
          };

          criterias.push(data);
        });
      });

      // console.log(this.presentation)

      let toSendData = {
        program_id: this.presentation.program_id,
        batch_id: this.presentation.program_batch_id,
        employee_id: this.presentation.employee_id,
        assessor_employee_id: this.$store.state.auth.user.employee_id,
        type: this.presentation.type,
        level: this.presentation.level,
        is_presentation: 1,
        point_criteria_ids: pointsCriIds,
        trainer_criterias: criterias,
      };

      // console.log(criteria);
      await axios
        .post(`${this.baseUrl}admin/v2/trainer-assessment-multiple`, toSendData)
        .then(() => {
          this.toast.success("Successfully Presentation Assessment Created.");
          this.$router.push({ name: "trainer-presentation-assessment" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.isLoading = false;
        });
    },
    async getPresentationAssessmentById() {
      this.loading = true;
      // this.$Progress.start();
      // await axios
      //   .get(
      //     `${this.baseUrl}admin/v2/trainer-assessment/${this.$route.params.id}`
      //   )
      //   .then(async (response) => {
      //     const data = response.data.data;
      //     this.selectedProgram = data.program_name;
      //     this.selectedBatch = data.batch_no;
      //     this.selectedEmployee = data.employee_name;
      //     // this.hrd_feedback = data.hrd_feedback;
      //     // this.background = data.background;
      //     // this.orientation = data;
      //       this.selectedLevel = data.level
      //       this.presentation.level = data.level
      //       this.presentation.program_id = data.program_id,
      //       this.presentation.program_batch_id = data.batch_id,
      //       this.presentation.employee_id = data.employee_id,
      //       this.presentation.join_date = data.join_date
      //       this.presentation.type = data.type
      //       // assessment_point : this.selectedWeight.point,
      //       // assessment_criteria_id: this.selectedWeight.id,
      //       // trainer_criterias : criteria
      //       // this.criterias = data.trainer_criterias
      //       await this.getAllCriterias();
      //       this.selectedWeight = data.assessment_criteria_id

      //   //   this.selectedWeight = this.weights.filter(
      //   //     (weight) =>
      //   //       weight.id ==
      //   //       data.trainer_presentation_assessment[0]
      //   //         .training_orientation_criteria.evaluating_weights.id
      //   //   );
      //     // console.log(data.trainer_orientation_assessment[0]
      //     //       .training_orientation_criteria.evaluating_weights.id, 'sele')
      //       //
      //     data.trainer_criterias.forEach((assessment) => {
      //       this.criterias.push({
      //         id: assessment.id,
      //         name: assessment.criteria_name,
      //         lowest_score: assessment.lowest_score,
      //         highest_score: assessment.highest_score,
      //         selectedScore: assessment.score,
      //         remark: assessment.remark,
      //       });
      //       this.updateTotalScore();

      //       //       this.loading = false;
      //       // this.$Progress.finish();
      //     });
      //   //   this.getBatchByProgram(data.program_id);
      //   //   this.getEmployeeByBatch(data.program_batch_id);
      //   })
      //   .catch((error) => {
      //     this.error = error.response;
      //     this.toast.error("Something went wrong.");
      //     this.loading = false;
      //     this.$Progress.finish();
      //   });

      await axios
        .get(
          `${this.baseUrl}admin/v2/trainer-assessment-multiple-details/${this.$route.params.id}`
        )
        .then((response) => {
          const data = response.data.data;
          this.selectedProgram = data.program?.title;
          this.selectedBatch = data.batch?.batch_no;
          this.selectedEmployee = data.employee?.name;
          this.selectedLevel = data.level;

          this.presentation.level = data.level;
          (this.presentation.program_id = data.program?.id),
            (this.presentation.program_batch_id = data.batch?.id),
            (this.presentation.employee_id = data.employee?.emp_id),
            (this.presentation.join_date = data.batch?.join_date);
          this.presentation.type = data.type;

          const array = [];
          const seenCriteriaIds = new Set(); // Use a Set for efficient ID tracking

          data?.trainer_assessment_details.forEach((assessment) => {
            const criteriaId =
              assessment?.trainer_criteria?.assessment_criteria_id;
            if (!seenCriteriaIds.has(criteriaId)) {
              seenCriteriaIds.add(criteriaId); // Mark this ID as seen

              const newAssessmentCriteria = {
                id: criteriaId,
                name: assessment?.trainer_criteria?.assessment_criteria?.name,
                totalScore: assessment?.score,
                trainer_criterias: [
                  {
                    id: assessment?.trainer_criteria?.id,
                    trainer_assessment_detail_id: assessment.id,
                    name: assessment?.trainer_criteria?.name,
                    lowest_score: assessment?.trainer_criteria?.lowest_score,
                    highest_score: assessment?.trainer_criteria?.highest_score,
                    selectedScore: assessment?.score,
                    remark: assessment?.remark,
                  },
                ],
              };

              array.push(newAssessmentCriteria);
            } else {
              // Find the existing assessment criteria and add the new trainer criteria
              const existingAssessmentCriteriaIndex = array.findIndex(
                (item) => item.id === criteriaId
              );
              if (existingAssessmentCriteriaIndex !== -1) {
                array[existingAssessmentCriteriaIndex].totalScore +=
                  assessment.score;
                array[existingAssessmentCriteriaIndex].trainer_criterias.push({
                  id: assessment?.trainer_criteria?.id,
                  trainer_assessment_detail_id: assessment.id,
                  name: assessment?.trainer_criteria?.name,
                  lowest_score: assessment?.trainer_criteria?.lowest_score,
                  highest_score: assessment?.trainer_criteria?.highest_score,
                  selectedScore: assessment?.score,
                  remark: assessment?.remark,
                });
              }
            }
          });

          this.weights = array;
        })
        .catch((error) => {
          console.error("Error fetching data:", error); // Handle errors!
        });
      this.loading = false;
    },
    async updateTrainerPresentationAssessment() {
      this.isLoading = true;
      // let trainer_criterias = this.criterias.map((criteria) => {
      // return {
      //     trainer_criteria_id: criteria.id,
      //     score: criteria.selectedScore,
      //     remark: criteria.remark,
      //     };
      // });

      // let toSendData = this.presentation;
      // toSendData.trainer_criterias = trainer_criterias;

      let criterias = [];

      this.weights.forEach((weight) => {
        weight.trainer_criterias.forEach((cri) => {
          const data = {
            id: cri.trainer_assessment_detail_id,
            score: cri.selectedScore,
            remark: cri.remark,
          };

          criterias.push(data);
        });
      });

      const toSendData = {
        trainer_criterias: criterias,
      };

      await axios
        .put(
          `${this.baseUrl}admin/v2/trainer-assessment-multiple/${this.$route.params.id}`,
          toSendData
        )
        .then(() => {
          this.toast.success("Successfully Presentation Assessment Updated.");
          this.$router.push({ name: "trainer-presentation-assessment" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.isLoading = false;
        });
    },
    async getExistedEmployees() {
      // this.loading = true;
      // this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/trainer-assessment?second_type=Presentation`
        )
        .then((response) => {
          this.existedEmployees = response?.data?.data?.filter(
            (emp) =>
              this.currentUserId &&
              emp.assessor_employee_id === this.currentUserId
          );
          this.loading = false;
          console.log(this.existedEmployees, "exis");
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong");
          // this.loading = false;
          // this.$Progress.finish();
        });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentUserId() {
      return this.$store.state.auth.user.employee_id;
    },
  },

  async created() {
    this.getExistedEmployees();
    if (this.currentRouteName == "trainer-presentation-assessment-update") {
      await this.getPresentationAssessmentById();
    }
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
.scoreBox {
  width: 262px;
  margin-left: 12px;
}
</style>
